import React from "react";
import { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
// import ReactGA from 'react-ga';
import Button from '@mui/material/Button';
import './consent.css';
// import {eventTrack} from '../../commonFunctions/ga-event';
import MaxisInsureLogo from '../../images/maxis-insure.png';
import AIALogo from '../../images/AIA-big-logo.svg';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import  secureLocalStorage  from  "react-secure-storage";
import { GET, POST } from "../../services/commonServices";
import {
  PURCHASE_SUBMISSION
} from "../../constents/apiEndPoints";
import Loader from '../commonComponents/loader';
import PdfViewer from '../commonComponents/pdfViewre';
import Model from '../commonComponents/model';
// import {redirection} from "../../commonFunctions/timeoutRedirection";
import BackButton from "../commonComponents/backButton";
import productDis from "./Maxis-AIA-Bill-Protection-PDS.pdf";
import policy from "./Maxis-AIA-Bill-Protection-Policy.pdf";
import declaration from "./Maxis Bill Protection - AIA Declaration.pdf";
import importentNotice from "./Maxis Bill Protection - AIA Important Notice.pdf";
import privacyStatement from "./Maxis Bill Protection - AIA Privacy Statement.pdf";
import termsOfUse from "./Maxis Bill Protection - AIA Terms of Use.pdf"

const Consent = () => {

    const [isChecked, setIsChecked] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageUrl, setpPageUrl] = useState("");
    const [pageType, setPageType] = useState("");
    const [modelOpen, setModelOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState("");

    let navigate = useNavigate();

    useEffect(()=>{
        // redirection(navigate)
        // ReactGA.pageview(window.location.pathname + window.location.search);
    },[])

    const handleClick = () => {
        setIsChecked(current => !current);
    }

    
    const handleClick2 = () => {
        setIsChecked2(current => !current);
    }
    const handleClick3 = () => {
        setIsChecked3(current => !current);
    }
    

    const continueClick=async ()=>{
        if(isChecked&&isChecked2){
        try{
        setLoading(true);
        let pricing_plan_id = secureLocalStorage.getItem("pricing_plan_id");
        let auto_renew = secureLocalStorage.getItem("auto_renew");
        let order_id = secureLocalStorage.getItem("order_id");
        let account_no = secureLocalStorage.getItem("account_no");
        let msisdn = secureLocalStorage.getItem("msisdn");

        auto_renew=JSON.parse(auto_renew);
        auto_renew=auto_renew?"Y":"N";
        let isPromotional=isChecked3?"Y":"N";
        
        let params={
            order_id,
            account_no,
            msisdn,
            pricing_plan_id,
            auto_renew,
            promotion_flag:isPromotional
            }
            
            let res = await POST(PURCHASE_SUBMISSION, params);
            // toast(JSON. stringify(res)+" ress");
            setLoading(false);
         console.log(res?.data?.status,"ressssss");

         let authToken=secureLocalStorage.getItem("authToken")


         if(res?.data?.status){
            console.log(res?.data,"ressddbjkjk")
            if(res?.data?.policyStatus=="Y"){
                // alert("already subscribed");
                // navigate("/alreadySubscribed")
                navigate(`/?token=${authToken}`);
            }else{
            let url=res?.data?.redirectionURL;
            window.location.replace(url);
            toast.success(res?.data?.status)
            }

         }else{
            toast.error("Error - "+res?.data?.reason);
         }
        // eventTrack('consent-page', 'continue-click', 'consent');
    }catch(err){
        setLoading(false);
        toast.error(err.message+" Error!");
    }
}
    }

    const handleOpen=(type)=>{
        // if(type=='pdf'){
        //     setPageType("pdf")
        // }else{
        //     setPageType("html")
        // }
        setPageType(type);
        setModelOpen(true);

    }
    const handleClose=()=>{
        setModelOpen(false);
    }
console.log(pageUrl,"pageUrl+++++");

const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  function openDialog(url) {
 // open a web page in a dialog box
window.open(url, "dialog", "width=500,height=500");
 }

    return (
        <div>
                  {/* <a href="/PlanSelection"><BackButton /></a> */}

            <Model url={pageUrl} open={modelOpen} handleOpen={handleOpen} handleClose={handleClose} type={pageType} />
            {/* <PdfViewer url={pdfUrl} /> */}
            <Loader open={loading} />
            <div className="Logo-container">
                <div className="Maxis-logo-container">
                    {/* <img className="Maxis-insure-logo" src={MaxisInsureLogo} alt='' /> */}
                    <h3 className="heading-top">Bill Protection</h3>
                </div>

                <div className="AIA-mid-logo">
                    <div><span className="Powered-by">Underwritten by</span></div>
                    <div><img className="AIA-logo" src={AIALogo} alt='' /></div>
                </div>
            </div>

            <div className="Divider"></div>

            <div className="Maxis-Desc">
                <p className="Maxis-Insure-desc1">Maxis would like to seek your explicit consent to disclose your user details including your Personal Data to AIA to be used for the registration and/or subscription for a specific AIA’s product and/or service. (AIA's companies in Malaysia are AIA Bhd., AIA General Berhad, AIA PUBLIC Takaful Berhad, AIA Pension and Asset Management Sdn. Bhd. and AIA Health Services.)</p>

                {/* <p className="Maxis-Insure-desc2">By clicking “Continue”, you agree to the <span className="tnc">Privacy Notice & Terms and Conditions</span>
                </p> */}
            </div>

            <div className="Divider"></div>

            
            {/* <a id="myLink" href="https://www.aia.com.my/en/index/terms-of-use.html" target="_blank">AIA exxxx</a>---
                        <a onClick={()=>openInNewTab("https://www.aia.com.my/en/index/terms-of-use.html")} >test newWindow</a>---
                        <a href="popup:https://www.aia.com.my/en/index/terms-of-use.html">test popup:</a>---
                        <a target="_parent" href="https://www.aia.com.my/en/index/terms-of-use.html">test _parent</a>---
                         <a onClick={()=>window.open('https://www.aia.com.my/en/index/terms-of-use.html', '_system')}>test window.open</a>
                          */}

            <div className="Allow-container">
                <div className="click-continue">
                    <div className="checkbox">
                        <Checkbox className="checkbox" onClick={handleClick} icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: "24px" }}/>} checkedIcon={<CheckBoxIcon sx={{ fontSize: "24px", color: "#3e4a56"}}/>} />
                    </div>
                    <div className="Allow-desc">
                        <p className="Allow-desc">By continuing, you hereby expressly consent to share your Personal Data with AIA and accept AIA’s
                       
                        {/* <a 
                        //   href="https://www.aia.com.my/en/index/terms-of-use.html" target="_blank"
                        //   onClick={()=>{setpPageUrl("http://insureech-frontend-staging.s3-website-ap-southeast-1.amazonaws.com/tnc"); handleOpen("terms-of-use")}}
                        // onClick={()=>{setpPageUrl("https://www.javatpoint.com/reactjs-tutorial");
                        onClick={()=>{setpPageUrl("https://www.aia.com.my/en/index/terms-of-use.html")
                        handleOpen("terms-of-use")}}
                        className="link-text">---Testtttt---</a> */}

                        {/* https://b8zmao2j1k.execute-api.ap-southeast-1.amazonaws.com/dev/en/index/terms-of-use.html */}
                        <a 
                        //   href="https://www.aia.com.my/en/index/terms-of-use.html" target="_blank"
                          onClick={()=>{setpPageUrl(termsOfUse); handleOpen("terms-of-use")}}
                        // onClick={()=>{setpPageUrl("https://bgicjlyuze.execute-api.ap-southeast-1.amazonaws.com/insurTech/api/proxy-helper/tnc"); handleOpen("terms-of-use")}}
                        className="link-text"> Terms of Use</a>
                          , AIA’s 
                         <a 
                         onClick={()=>{setpPageUrl(privacyStatement); handleOpen("privacy-statement")}}
                         //  onClick={()=>{setpPageUrl("https://b8zmao2j1k.execute-api.ap-southeast-1.amazonaws.com/dev/en/index/privacy-statement.html"); handleOpen("privacy-statement")}}
                        // onClick={()=>openDialog('https://www.aia.com.my/en/index/privacy-statement.html')}
                       className="link-text"> Privacy Statement</a> and read and understood the 
                        <a 
                        onClick={()=>{setpPageUrl(importentNotice); handleOpen("important-notice-conventional")}}
                        // onClick={()=>{setpPageUrl("https://b8zmao2j1k.execute-api.ap-southeast-1.amazonaws.com/dev/en/my-aia/ecommerce/calculator/important-notice/important-notice-conventional.html"); handleOpen("important-notice-conventional")}}
                        // onClick={()=>openDialog('https://www.aia.com.my/en/my-aia/ecommerce/calculator/important-notice/important-notice-conventional.html')} 
                        className="link-text"> Important Notice, </a>
                        <a
                         onClick={()=>{setpPageUrl(declaration); handleOpen('declaration-conventional')}}
                         //  onClick={()=>{setpPageUrl("https://b8zmao2j1k.execute-api.ap-southeast-1.amazonaws.com/dev/en/my-aia/ecommerce/calculator/declaration/declaration-conventional.html"); handleOpen('declaration-conventional')}}
                        // onClick={()=>openDialog('https://www.aia.com.my/en/my-aia/ecommerce/calculator/declaration/declaration-conventional.html')} 
                        className="link-text">Declaration,</a> 
                        <a 
                        // href="https://www.aia.com.my/content/dam/my/en/docs/Maxis/Maxis-AIA-Bill-Protection-Policy.pdf" target="_blank"
                        onClick={()=>{setpPageUrl(policy); handleOpen("Maxis-AIA-Bill-Protection-Policy")}}
                        // onClick={()=>openDialog('https://www.aia.com.my/content/dam/my/en/docs/Maxis/Maxis-AIA-Bill-Protection-Policy.pdf')}
                       className="link-text"> Policy Wording</a> and 
                        <a 
                        // href="https://www.aia.com.my/content/dam/my/en/docs/Maxis/Maxis-AIA-Bill-Protection-PDS.pdf" target="_blank"  
                        onClick={()=>{setpPageUrl(productDis); handleOpen("Maxis-AIA-Bill-Protection-PDS")}}
                    //    onClick={()=>openDialog('https://www.aia.com.my/content/dam/my/en/docs/Maxis/Maxis-AIA-Bill-Protection-PDS.pdf')}
                    rel='noopener noreferrer' className="link-text"> Product Disclosure</a> Sheet by AIA.</p>

                    </div>
                </div>

                <div className="click-continue">
                    <div className="checkbox">
                        <Checkbox className="checkbox" onClick={handleClick2} icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: "24px" }}/>} checkedIcon={<CheckBoxIcon sx={{ fontSize: "24px", color: "#3e4a56"}}/>} />
                    </div>
                    <div className="Allow-desc">
                        <p className="Allow-desc">I acknowledge that I will only be entitled for the job loss benefit if I am NOT self employed and I have been in employment for the past 3 months.</p>
                    </div>
                </div>

                
                <div className="click-continue">
                    <div className="checkbox">
                        <Checkbox className="checkbox" 
                        onClick={handleClick3} 
                        icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: "24px" }}/>} checkedIcon={<CheckBoxIcon sx={{ fontSize: "24px", color: "#3e4a56"}}/>} />
                    </div>
                    <div className="Allow-desc">
                        {/* <p className="Allow-desc">Please count me in for any special offers, promotions and news updates directly from AIA's companies in Malaysia. I understand and consent that information collected about me is to be processed by AIA's companies for this purpose.
                            </p> */}
                             <p className="Allow-desc">I agree to receive special offers, promotions and news updates directly from AIA companies in Malaysia, and consent that information about me is to be processed for this purpose upon policy effectivity and renewal. (AIA's companies in Malaysia are AIA Bhd., AIA General Berhad, AIA PUBLIC Takaful Bhd., AIA Pension and Asset Management Sdn. Bhd. and AIA Health Services Sdn. Bhd.)
                             </p>
                    </div>
                </div>

                {/* <div className="AIA-copysheet">
                    <div className="checkbox">
                        <Checkbox className="checkbox"  icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: "24px" }}/>} checkedIcon={<CheckBoxIcon sx={{ fontSize: "24px", color: "#40c706"}}/>} />
                    </div>
                    <div className="Allow-desc">
                        <p className="Allow-desc">Please count me in for any special offers, promotions and news updates directly from AIA's companies in Malaysia. I understand
                         and consent that information collected about me is to be processed by AIA's companies for this purpose.(AIA's companies in Malaysia are AIA Bhd.,
                        AIA General Berhad, AIA PUBLIC Takaful Berhad, AIA Pension and Asset Management Sdn. Bhd. and AIA Health Services.)</p>
                    </div>
                </div> */}
            </div>

            <div className="Continue-btn">
                <Button className="Continue-btn" 
                onClick={continueClick}
                style={{ backgroundColor: (isChecked&&isChecked2)? "#3e4a56":"#c2c3c4", width:"100%", borderRadius:"0px"}}>
                    <span className='continue-btn-text'>Continue</span>
                </Button>
            </div>


                {/* <button onClick={openDialog}>Open Dialog</button> */}


        </div>
    )
}

export default Consent;